.c-card-slider {
  width: 100%;
  position: relative;
}
.c-card-slider__list {
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 16px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
  scroll-behavior: smooth;
}
.c-card-slider__list.center {
  justify-content: center;
}
.c-card-slider.center .c-card-slider__list {
  justify-content: center;
}
.c-card-slider__list__item {
  width: 375px;
  transition: transform 500ms;
  flex-shrink: 0;
}
.c-card-slider__list__item + .c-card-slider__list__item {
  /* margin-left: 16px; */
}
.c-card-slider__prev,
.c-card-slider__next {
  width: 96px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1;
}
.c-card-slider__prev button,
.c-card-slider__next button {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}
.c-card-slider__prev button::after,
.c-card-slider__next button::after {
  content: '';
  width: 16px;
  height: 16px;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
  transform: rotate(-45deg);
  position: absolute;
  top: 22px;
  left: 24px;
}
.c-card-slider__prev {
  left: 0;
}
.c-card-slider__next {
  right: 0;
  transform: scale(-1, 1);
}
@media screen and (max-width: 768px) {
  .c-card-slider.center .c-card-slider__list {
    justify-content: flex-start;
  }
  .c-card-slider__list {
    width: 100%;
    padding: 16px 16px;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
  }
  .c-card-slider__list__item {
    width: 300px;
  }
  .c-card-slider__prev,
  .c-card-slider__next {
    display: none;
  }
}
