.base-info__editor {
  width: 100%;
  max-width: 960px;
  padding: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 8px;
  background: #fff;
  background: var(--theme-content-section-background-color);
}
